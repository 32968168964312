import React from 'react'
import { Box } from '@mui/material'

import Section from '@components/core/container/section'
import Headline from '@components/core/text/headline'
import DownloadButton from '@components/core/ui/downloadButton'
import { numberToByteSize } from '@utils/string-tools'

export type DownloadsProps = SOG.Props.IDefault & {
  headline: string | React.ReactElement
  theme: SOG.Contentful.Theme
  downloads: SOG.Props.Asset[]
}

export default function Downloads({
  theme,
  headline,
  downloads,
}: DownloadsProps): React.ReactElement {
  return (
    <Section theme={theme} withSeparator paddingSize="lg">
      <Headline variant="h3">{headline}</Headline>
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          mt: 10,
          [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            mt: 16,
          },
        })}
      >
        {downloads.map((download, index) => {
          return (
            <DownloadButton
              theme={theme}
              key={index}
              title={download.title || ''}
              info={numberToByteSize(download.file?.details?.size || 0)}
              url={download.file?.url}
              sx={(theme) => ({
                flex: '1',
                mb: index === 0 ? 2 : 0,
                [theme.breakpoints.up('sm')]: {
                  mb: 0,
                  mr: index === 0 ? 8 : 0,
                },
              })}
            />
          )
        })}
      </Box>
    </Section>
  )
}
