import React from 'react'
import { Box } from '@mui/material'

import Section from '@components/core/container/section'
import Headline from '@components/core/text/headline'
import Copy from '@components/core/text/copy'
import Image from '@components/core/media/image'

export type TeaserBrandProps = SOG.Props.IDefault & {
  theme: SOG.Contentful.Theme
  brand: SOG.Props.IGlobalBrand
}

export default function TeaserBrand({
  theme,
  brand,
}: TeaserBrandProps): React.ReactElement {
  return (
    <Section theme={theme} withSeparator paddingSize="lg">
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column-reverse',
          [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
          },
        })}
      >
        <Box
          sx={(theme) => ({
            [theme.breakpoints.down('lg')]: {
              mt: 10,
            },
            [theme.breakpoints.up('lg')]: {
              width: !!brand.image ? '50%' : '100%',
              pr: 10,
            },
          })}
        >
          <Headline variant="h3">{brand.title}</Headline>
          <Copy
            sx={{
              mt: 8,
            }}
          >
            {brand.description}
          </Copy>
        </Box>
        {!!brand.image && (
          <Image
            objectFit="cover"
            objectPosition="center"
            sx={(theme) => ({
              height: '100%',
              mr: -6,
              [theme.breakpoints.up('lg')]: {
                width: '50%',
                mr: -8,
              },
            })}
            {...(brand.image as SOG.Props.Image)}
          />
        )}
      </Box>
    </Section>
  )
}
