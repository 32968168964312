import React from 'react'
import { Grid } from '@mui/material'

import Section from '@components/core/container/section'
import Headline from '@components/core/text/headline'
import NewsCard from '@components/core/ui/newsCard'

export type NewsListProps = SOG.Props.IDefault & {
  type: 'articleDetail' | 'default'
  theme: SOG.Contentful.Theme
  headline?: string | React.ReactElement
  entries: SOG.Props.IPageArticleDetail[]
}

export default function NewsList({
  theme,
  type = 'default',
  headline,
  entries = [],
}: NewsListProps): React.ReactElement {
  return (
    <Section theme={theme} paddingSize={type === 'articleDetail' ? 'lg' : 'md'}>
      {headline && (
        <Headline
          variant="h3"
          sx={(theme) => ({
            mb: 10,
            [theme.breakpoints.up('md')]: {
              mb: 16,
            },
          })}
        >
          {headline}
        </Headline>
      )}
      <Grid
        container
        columnSpacing={10}
        rowSpacing={type === 'articleDetail' ? 10 : { xs: 8, lg: 12 }}
      >
        {entries.map((entry, index) => (
          <Grid item key={index} xs={12} md={type === 'articleDetail' ? 4 : 12}>
            <NewsCard
              linkTo={entry.linkTo}
              image={entry.image}
              topic={entry.topic?.title}
              type={type}
              headline={entry.headline}
              subline={entry.subline}
              releaseDate={entry.releaseDate}
              theme={theme}
            />
          </Grid>
        ))}
      </Grid>
    </Section>
  )
}
