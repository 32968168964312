import React, { ReactElement, useState } from 'react'
import { graphql } from 'gatsby'
import { BLOCKS } from '@contentful/rich-text-types'
import { Container, Typography, Box } from '@mui/material'
import { DocumentNodeType, richTextOptions } from '@utils/richtext'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { getAssetProp, getGlobalProp } from '@utils/props'
import Image from '@components/core/media/image'
import useContentfulPageArticleDetail from '@components/system/hooks/contentful/useContentfulPageArticleDetail'
import Downloads from '@components/modules/downloads'
import { FormattedMessage } from 'react-intl'
import Contacts from '@components/modules/contacts'
import NewsList from '@components/modules/newsList'
import TeaserBrand from '@components/modules/teaserBrand'
import { timestampToLocaleDate } from '@utils/string-tools'
import RelatedContentTeaser from '@components/modules/relatedContentTeaser'
import { getPressRelatedPages } from '@utils/props'
import { PageState } from '@components/core/container/layout'

export default function PageArticleDetail({
  data,
  location,
  pageContext,
}: SOG.Contentful.IPageArticleDetail): ReactElement {
  const {
    topic,
    releaseDate,
    headline,
    subline,
    image,
    content,
    downloads,
    contacts,
    brand,
  } = data.contentfulPageArticleDetail

  const [currentPageState, setCurrentPageState] = useState<PageState>({
    location,
    pageContext,
  })

  const document = content && JSON.parse(content.raw)
  const documentContent: Array<DocumentNodeType> = document?.content
    .map((component: DocumentNodeType, componentIndex: number) => {
      switch (component.nodeType) {
        case BLOCKS.EMBEDDED_ASSET:
        case BLOCKS.HEADING_1:
        case BLOCKS.HEADING_2:
        case BLOCKS.HEADING_3:
        case BLOCKS.HEADING_4:
        case BLOCKS.HEADING_5:
        case BLOCKS.HEADING_6:
        case BLOCKS.PARAGRAPH:
        case BLOCKS.UL_LIST:
        case BLOCKS.OL_LIST:
        case BLOCKS.QUOTE:
          // remove empty last paragraph
          if (componentIndex == document.content.length - 1) {
            if (
              component.content &&
              component.content.length == 1 &&
              component.content[0].nodeType == 'text' &&
              component.content[0].value == ''
            ) {
              return null
            }
          }
          return {
            ...component,
            data: {
              ...component.data,
              container: true,
            },
          }
        case BLOCKS.EMBEDDED_ENTRY:
          return {
            ...component,
            data: {
              ...component.data,
              location,
              container: true,
            },
          }
        default:
          return component
      }
    })
    .filter((entry: DocumentNodeType | null) => !!entry)
  const relatedNewsArticles = useContentfulPageArticleDetail([topic], 4)
    .filter((articlePage) => articlePage.id !== pageContext.id)
    .slice(0, 3)

  const relatedPages =
    !!data?.relatedTeaser?.relatedPages &&
    getPressRelatedPages(data.relatedTeaser.relatedPages)

  return (
    <>
      <Container
        maxWidth="sm"
        sx={(theme) => ({
          mt: 12,
          mb: 10,
          [theme.breakpoints.up('md')]: {
            mt: 31,
            mb: 20,
          },
        })}
      >
        <Typography
          variant="caption"
          sx={(theme) => ({
            display: 'inline-block',
            mb: 2,
            mr: 3,
            [theme.breakpoints.up('md')]: {
              mb: 4,
            },
          })}
        >
          {topic?.title}
        </Typography>
        <Typography variant="small" component="span">
          {timestampToLocaleDate(releaseDate)}
        </Typography>
        <Typography
          variant="h3"
          component="h1"
          sx={(theme) => ({
            mb: 4,
            [theme.breakpoints.up('md')]: {
              mb: 8,
            },
          })}
        >
          {headline}
        </Typography>
        <Typography variant="subline">{subline}</Typography>
      </Container>
      <Container maxWidth="md">
        <Image {...((getAssetProp(image) || {}) as SOG.Props.Image)} />
      </Container>
      <div>
        {documentContent &&
          renderRichText(
            {
              raw: JSON.stringify({
                data: document.data,
                content: documentContent,
                nodeType: document.nodeType,
              }),
              references: content.references,
            },
            richTextOptions
          )}
      </div>
      {brand && (
        <TeaserBrand
          brand={getGlobalProp(brand) as SOG.Props.IGlobalBrand}
          theme="medium"
        />
      )}
      {downloads && (
        <Downloads
          downloads={downloads}
          theme="medium"
          headline={<FormattedMessage id="label.downloads" />}
        />
      )}
      {contacts && (
        <Contacts
          theme="medium"
          withSeparator
          entries={
            contacts.map((entry) =>
              getGlobalProp(entry)
            ) as SOG.Props.IGlobalContact[]
          }
          headline={<FormattedMessage id="label.contacts" />}
        />
      )}
      {relatedNewsArticles.length > 0 && (
        <NewsList
          theme="medium"
          type="articleDetail"
          headline={<FormattedMessage id="label.relatedNews" />}
          entries={relatedNewsArticles}
        />
      )}
      <Box
        sx={() => ({ color: 'text.inverted', marginBottom: '0px !important' })}
      >
        {relatedPages && (
          <RelatedContentTeaser
            entries={relatedPages}
            currentPageState={currentPageState}
          />
        )}
      </Box>
    </>
  )
}

export const pageQuery = graphql`
  query PageArticleDetailByPath($id: String!, $locale: String!) {
    contentfulPageArticleDetail(id: { eq: $id }, node_locale: { eq: $locale }) {
      topic {
        ...GlobalTopicFragment
      }
      headline
      subline
      releaseDate
      metadata {
        tags {
          name
        }
      }
      image {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        description
        title
        file {
          url
          fileName
          contentType
        }
      }
      content {
        raw
        references {
          ...RichTextFragment
        }
      }
      downloads {
        id
        contentful_id
        __typename
        description
        title
        file {
          url
          fileName
          contentType
          details {
            size
          }
        }
      }
      contacts {
        ...GlobalContactFragment
      }
      brand {
        ...GlobalBrandFragment
      }
    }

    relatedTeaser: contentfulGlobalRelatedTeaser(
      titleInternal: { eq: "Global Article Detail Related Teaser" }
    ) {
      id
      relatedPages {
        __typename
        contentful_id
        id
        node_locale
        url
        titleNavigation
        teaserTitle
        teaserCopy {
          raw
        }
        teaserImage {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          title
          description
          file {
            url
            fileName
            contentType
          }
        }
        fields {
          linkTo {
            title
            label
            url
            isExternal
          }
        }
      }
    }
  }
`
