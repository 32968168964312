import React, { ReactElement } from 'react'

import { Box, Typography } from '@mui/material'
import Headline from '@components/core/text/headline'
import Icon from '@components/core/ui/icon'
import Link from '@components/core/ui/link'

export type DownloadButtonProps = SOG.Props.IDefault & {
  title: string
  info: string
  url: string
  theme?: SOG.Contentful.Theme
}

export default function DownloadButton({
  theme,
  title,
  info,
  url,
  sx,
}: DownloadButtonProps): ReactElement {
  return (
    <Link isExternal={true} to={url} sx={sx} isDownload={true}>
      <Box
        sx={(sxTheme) => ({
          display: 'flex',
          alignItems: 'flex-start',
          border: '2px solid',
          borderColor: sxTheme.palette.grey[500],
          backgroundColor:
            theme === 'dark'
              ? 'background.dark'
              : theme === 'medium'
              ? 'background.medium'
              : 'background.light',
          p: 6,
          [sxTheme.breakpoints.up('md')]: {
            p: 8,
          },
        })}
      >
        <Icon
          name="Download"
          color="inherit"
          sx={{
            color:
              theme === 'dark' || theme === 'medium'
                ? 'common.white'
                : 'common.black',
            mr: 4,
          }}
        />
        <Box>
          <Headline
            variant="h6"
            component="span"
            sx={{
              display: 'block',
              color:
                theme === 'dark' || theme === 'medium'
                  ? 'common.white'
                  : 'common.black',
              mb: 2,
            }}
          >
            {title}
          </Headline>
          <Typography
            variant="baseBold"
            sx={{
              color:
                theme === 'dark' || theme === 'medium'
                  ? 'common.white'
                  : 'common.black',
              textTransform: 'uppercase',
            }}
          >
            {info}
          </Typography>
        </Box>
      </Box>
    </Link>
  )
}
